import { ETipiUtente } from './profilo';

export class InformazioniBackOffice {
  usersNumber: number = 0;
  projectsNumber: number = 0;
  storageSpaceOccupiedInByte: number = 0;
  storageSpaceOccupied: string = "";
}

export class Utenti {
  id: string = "";
  nome: string = "";
  cognome: string = "";
  email: string = "";
  immagine: any | null = null;
  attivo: boolean = false;
  isAdmin: boolean = false;
  spazioOccupatoInByte: number = 0;
  spazioOccupato: string = "";
}

export class GrigliaUtenti {
  itemsCount: number = 0;
  itemsPerPage: number = 0;
  users: Utenti[] = [];
  pageNumber: number = 0;
}

export class Utente {
  abbonamentoDataScadenza: Date | undefined;
  abbonamentoDescrizione: string = "";
  abbonamentoPrezzo: number = 0;
  abbonamentoNome: string = "";
  abbonamentoSpazioTotaleInByte: number = 0;
  abbonamentoSpazioTotale: string = "";
  attivo: boolean = false;
  codiceFiscale: string = "";
  cognome: string = "";
  denominazione: string = "";
  email: string = "";
  id: string = "";
  immagine: any | null = null;
  indirizzo: string = "";
  isAdmin: boolean = false;
  nazione: string = "";
  nome: string = "";
  numeroCondivisioniEffettuate: number = 0;
  numeroProgetti: number = 0;
  partitaIva: string = "";
  percentualeSpazioOccupato: number = 0;
  spazioOccupatoInByte: number = 0;
  spazioOccupato: string = "";
  tipoUtente: ETipiUtente | null = null;
}

export class Ordini {
  ordineData: Date | undefined;
  ordineId: number = 0;
  ordinePrezzo: number = 0;
  ordineStato: number = 0;
  ordineTipo: number = 0;
  pianoAbbonamentoId: number = 0;
  pianoAbbonamentoNome: string = "";
  utenteCognome: string = "";
  utenteId: string = "";
  utenteNome: string = "";
}

export class GrigliaOrdini {
  itemsCount: number = 0;
  itemsPerPage: number = 0;
  orders: Ordini[] = [];
  pageNumber: number = 0;
}
