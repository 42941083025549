import { Injectable } from '@angular/core';
import { HttpServices } from './http.service'
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { InformazioniBackOffice, Utenti, Utente, Ordini, GrigliaOrdini, GrigliaUtenti } from '../models/backOffice';
import { StoricoAbbonamenti, Abbonamento, EStatiOrdine, ETipiOrdine, GrigliaStoricoAbbonamenti } from '../models/abbonamento';

@Injectable({
  providedIn: 'root'
})
export class BackofficeService extends HttpServices {

  getDashboardInfo(): Observable<InformazioniBackOffice> {
    return this.get('/api/backoffice/Dashboard/GetDashboardInfo')
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

  getSubscriptionHistory(UserId: string, PageNumber: number, ItemsPerPage: number, FilterStatoOrdine?: EStatiOrdine, FilterTipoOrdine?: ETipiOrdine, FilterDataDa?: Date, FilterDataA?: Date): Observable<GrigliaStoricoAbbonamenti> {
    var form: any = {};
    form.UserId = UserId;
    form.PageNumber = PageNumber;
    form.ItemsPerPage = ItemsPerPage;
    FilterStatoOrdine ? form.FilterStatoOrdine = Number(FilterStatoOrdine) : null;
    FilterTipoOrdine ? form.FilterTipoOrdine = Number(FilterTipoOrdine) : null;
    FilterDataDa ? form.FilterDataDa = FilterDataDa : null;
    FilterDataA ? form.FilterDataA = FilterDataA : null;
    return this.post('/api/backoffice/Order/PaginateAndFilterOrderHistoryByUserId/', form)
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

  getUsers(PageNumber: number, ItemsPerPage: number, FilterText?: string): Observable<GrigliaUtenti> {
    var form: any = {};
    form.PageNumber = PageNumber;
    form.ItemsPerPage = ItemsPerPage;
    FilterText ? form.Filter = FilterText : null;

    return this.post('/api/backoffice/User/PaginateAndFilterUsers', form)
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

  getUserById(idUtente: string): Observable<Utente> {
    return this.get('/api/backoffice/User/GetUserById/' + idUtente)
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

  modifySubscriptionPlanExpirationDate(idUtente: string, newSubscriptionPlanExpirationDate: Date): Observable<Utente> {
    var form: any = {};
    form.UserId = idUtente;
    form.NewSubscriptionPlanExpirationDate = newSubscriptionPlanExpirationDate;
    return this.put('/api/backoffice/Subscription/ModifySubscriptionPlanExpirationDate', form)
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

  deactivateUser(idUtente: string): Observable<boolean> {
    return this.put('/api/backoffice/User/DeactivateUser/' + idUtente, null)
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

  activateUser(idUtente: string): Observable<boolean> {
    return this.put('/api/backoffice/User/ActivateUser/' + idUtente, null)
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

  deleteAllegatiByUserId(idUtente: string): Observable<boolean> {
    return this.delete('/api/backoffice/User/DeleteAllegatiByUserId/' + idUtente, null)
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

  getOrders(PageNumber: number, ItemsPerPage: number, FilterText?: string, FilterStatoOrdine?: EStatiOrdine, FilterTipoOrdine?: ETipiOrdine, FilterDataDa?: Date, FilterDataA?: Date): Observable<GrigliaOrdini> {
    var form: any = {};
    form.PageNumber = PageNumber;
    form.ItemsPerPage = ItemsPerPage;
    FilterText ? form.FilterText = FilterText : null;
    FilterStatoOrdine ? form.FilterStatoOrdine = Number(FilterStatoOrdine) : null;
    FilterTipoOrdine ? form.FilterTipoOrdine = Number(FilterTipoOrdine) : null;
    FilterDataDa ? form.FilterDataDa = FilterDataDa : null;
    FilterDataA ? form.FilterDataA = FilterDataA : null;

    return this.post('/api/backoffice/Order/PaginateAndFilterOrders', form)
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

  renewSubscriptionPlan(userId: string, forceFree: boolean = true): Observable<Abbonamento[]> {
    return this.put('/api/backoffice/Subscription/RenewSubscriptionPlan/' + userId + '/' + forceFree, null)
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

  getSubscriptionPlansForConfiguration(): Observable<Abbonamento[]> {
    return this.get('/api/backoffice/Subscription/GetSubscriptionPlansForConfiguration/false')
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

  createSubscriptionPlan(abbonamento: Abbonamento, isAbbonamentoGratuito: boolean): Observable<boolean> {
    var form: any = {};
    form.Nome = abbonamento.nome;
    form.Descrizione = abbonamento.descrizione;
    form.Prezzo = isAbbonamentoGratuito ? null : Number(abbonamento.prezzo);
    form.SpazioArchiviazione = Number(abbonamento.spazioArchiviazione);
    form.DurataInMesi = isAbbonamentoGratuito ? 2147483647 : 12;
    return this.post('/api/backoffice/Subscription/CreateSubscriptionPlan', form)
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

  deleteSubscriptionPlan(abbonamentoId: number): Observable<boolean> {
    return this.delete('/api/backoffice/Subscription/DeleteSubscriptionPlan/' + abbonamentoId)
      .pipe(catchError((err: any) => { return throwError(err); })
        , map((res: any) => { return res; }));
  }

}
