import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export class Coordinate {
  latitude: number = 0;
  longitude: number = 0;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardFactory {

  coordinates = new BehaviorSubject<Coordinate>({latitude: 0, longitude: 0 });
  zoom = new BehaviorSubject<any>(0);

  updateCoordinates(LT: number, LG: number) {
    var currentCoordinates = new Coordinate();
    currentCoordinates.latitude = LT;
    currentCoordinates.longitude = LG;
    this.coordinates.next(currentCoordinates);
  }

  updateZoom(value: any) {
    this.zoom.next(value);
  }
}
